<template>
  <div>

  <h3>{{name}}</h3>
  <a-table                 :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :scroll="tscroll"
                size="small"
            >
              <template slot="apr" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.apr" v-if="record.editApr =='true'" @change="e => handleValChange(text,e.target.value, record.key,'apr')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
              <template slot="may" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.may" v-if="record.editMay =='true'" @change="e => handleValChange(text,e.target.value, record.key,'may')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
              <template slot="jun" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.jun" v-if="record.editJun =='true'" @change="e => handleValChange(text,e.target.value, record.key,'jun')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
              <template slot="jul" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.jul" v-if="record.editJul =='true'" @change="e => handleValChange(text,e.target.value, record.key,'jul')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
              <template slot="aug" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.aug" v-if="record.editAug =='true'" @change="e => handleValChange(text,e.target.value, record.key,'aug')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            <template slot="sep" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.sep" v-if="record.editSep =='true'" @change="e => handleValChange(text,e.target.value, record.key,'sep')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            <template slot="oct" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.oct" v-if="record.editOct =='true'" @change="e => handleValChange(text,e.target.value, record.key,'oct')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            
            <template slot="nov" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.nov" v-if="record.editNov =='true'" @change="e => handleValChange(text,e.target.value, record.key,'nov')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            <template slot="dec" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.dec" v-if="record.editDec =='true'" @change="e => handleValChange(text,e.target.value, record.key,'dec')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            <template slot="jan" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.jan" v-if="record.editJan =='true'" @change="e => handleValChange(text,e.target.value, record.key,'jan')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            <template slot="feb" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.feb" v-if="record.editFeb =='true'" @change="e => handleValChange(text,e.target.value, record.key,'feb')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>
            <template slot="mar" slot-scope="text, record">
              <div class="editable-row-operations">
                <a-input v-model="record.mar" v-if="record.editMar =='true'" @change="e => handleValChange(text,e.target.value, record.key,'mar')">
                
                </a-input>
                <span v-else>
                  {{text}}
                </span>
              </div>
            </template>

            </a-table>
  </div>
</template>
<script>




export default {
  name: 'EnePointTable',
  props: {
    name:{
      type:String,
      default:''
    },
    columns:{
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    fatherChangeMethod: {
        type: Function,
        default: null
    },
    tscroll:{
       type: Object,
       default: { y: 200 },
    }
  }, 
  data() {
    return {
      pagination:false,
    };
  },
  watch:{
    "data":{
      handler(val){
        //this.selectedRowKeys = [];
      },
      deep:true,
      immediate:true,
    },
  },
  computed: {
   
  },
  methods: {
    handleValChange(ov,value, key,col){
       let v = new Number(value);
      console.log(isNaN(v));
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      if(isNaN(v)){
          this.$message.error(this.$t('energy.enedata038'));
          if (target) {
              target[col] = ov;
          }
          return;
      }
      target['chgflg'] = true;
      switch(col){
        case "apr":
          target['tagApr'] = true;
          break;
				case "may":
          target['tagMay'] = true;
          break;
				case "jun":
          target['tagJun'] = true;
          break;
				case "jul":
          target['tagJul'] = true;
          break;
				case "aug":
          target['tagAug'] = true;
          break;
				case "sep":
          target['tagSep'] = true;
          break;
				case "oct":
          target['tagOct'] = true;
          break;
				case "nov":
          target['tagNov'] = true;
          break;
				case "dec":
          target['tagDec'] = true;
          break;
				case "jan":
          target['tagJan'] = true;
          break;
				case "feb":
          target['tagFeb'] = true;
          break;
				case "mar":
          target['tagMar'] = true;
          break;
        default:
          break;
      }
      console.log('ov',ov);
      console.log('value',value);
      console.log('key',key);
      console.log('col',col);
      // if(this.fatherChangeMethod){
      //   this.fatherChangeMethod(ov,value, key,col);
      // }
    }
  },
};
</script>
