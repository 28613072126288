<!--电能设置--基本设置---->
<template>
    <div id="energyActualnputData" ref="" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <div class="header-title">
            <span>{{ $t("energy.enedata085") }}</span>
        </div>

        <div class="header">
            <div class="header-left">
                <div class="header-condition">
                    <span>{{ $t("energy.enedata012") }}</span>
                    <a-radio-group v-model="rpt" button-style="solid" @change="rptChange" :disabled="disable">
                        <a-radio-button  v-for="(itme, index) in this.eneShowKindList"  
                            :key="index"
                            :value="itme.no"> {{ itme.text }}</a-radio-button>
                    </a-radio-group>
                </div>
            
                <div class="header-condition">
                    <span>{{ $t("energy.enedata005") }}</span>
                    <a-select v-model="year" style="width: 120px" @change="yearChange">
                        <a-select-option
                            v-for="(itme, index) in this.yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="header-condition">
                    <span>{{$t("energy.enedata398")}}</span>
                    <a-switch v-model="msel" @change="mselChange" :disabled="disable" :style="{'width':'60px','margin-top':'5px'}" />
                </div>
                <div class="header-condition">
                    <span>{{$t("energy.enedata399")}}</span>
                    <a-select v-model="month" style="width: 120px" :disabled="defaultStatistic==0" >
                    <a-select-option
                        v-for="(itme, index) in this.monthList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="header-condition">
                    <span>&nbsp;--&nbsp;</span>
                    <a-checkbox v-model="tenantInput" @change="tenantInputChange" :disabled="disable"> {{$t("energy.enedata083")}} </a-checkbox>
                </div>
               <div class="header-condition">
                    <span>{{ $t("energy.enedata075") }}</span>
                    <a-radio-group v-model="kind" button-style="solid" @change="kindChanges">
                        <!--   <a-radio-button value="1">{{ $t("energy.enedata076") }}</a-radio-button>-->
                        <a-radio-button value="2">{{ $t("energy.enedata077") }}</a-radio-button>
                        <a-radio-button value="3">{{ $t("energy.enedata078") }}</a-radio-button>
                    </a-radio-group>
                </div> 
            </div>
            <div>
                <a-button  type="primary"   @click="search()" :disabled="disable"> {{ $t("energy.enedata006") }} </a-button>
                <a-button  type="primary"   @click="csvout()" :disabled="disable"> {{ $t("energy.enedata042") }} </a-button>
            </div>
        </div>

        <div class="main" ref="main">
            
            <div v-if="data!=null && data.length>0">
              <div style="width:100%;heigth:600px;" v-for="(item,index) in data"  :key="index" >
                <eai-table 
                  :columns="columns" 
                  :data="item.list" 
                  :name="item.name" 
                  :fatherChangeMethod="handleValChange" 
                  :tscroll="xscroll">
                </eai-table>
              </div>
            </div>
             <div v-else>
               <a-empty />
             </div>
        </div> 
        <div style="text-align:right; padding-top:10px;">
            <!--
              :disabled="defaultStatistic==0" || (defaultStatistic==1)
              <a-button  type="primary"   @click="computationalCost()" :disabled="(rpt!='1' && rpt!='3') && (data == null || data.length<=0)"> {{$t("energy.enedata084")}}</a-button> -->
            <a-button  type="primary"   @click="save()" :disabled="(data ==null || data.length<=0) "> {{ $t("energy.enedata023") }} </a-button>
        </div> 

         <a-modal v-model="visible" :title="costTitle" @ok="handleOk">
                <div>
                    <div class="header-condition" v-if="rpt=='1'">
                      <span>{{ $t("energy.enedata100") }}</span>
                      <a-radio-group v-model="ek" button-style="solid" @change="priceChange">
                          <a-radio-button value="1">1~10KV</a-radio-button>
                          <a-radio-button value="2">20KV</a-radio-button>
                          <a-radio-button value="3">35~110KV</a-radio-button>
                          <a-radio-button value="4">220KV</a-radio-button>
                      </a-radio-group>
                      <a-statistic :title="ptitle" :precision="6" v-model="price" />
                  </div>
                  <div class="header-condition" v-else-if="rpt=='3'">
                      <span>{{ reportName }}{{ $t("energy.enedata096") }}</span>
                      <a-statistic :title="ptitle" :precision="6" v-model="price" />
                  </div>
                </div>
            </a-modal>  
   
    </div>
</template>



<script>
import { getEnergyActualInputData,saveEnergyActualInputData,downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment"; 
import eaiTable from "../components/energyActuaInputDataTable";
const waterPrice = 3.46;
const cost1 ={
    KV1:63.806875,
    KV2:63.486875,
    KV3:61.306875,
    KV4:58.806875,
}
const cost2 ={
    normal:cost1,
    peak:{
      KV1:103.486875,
      KV2:102.956875,
      KV3:99.356875,
      KV4:95.236875,
    },
    valley:{
      KV1:36.396875,
      KV2:35.146875,
      KV3:34.936875,
      KV4:33.896875,
    }
}
export default {
  name: "energyActualnputData",
  data() {
    
    return {
      loading: false,
      eneKindList:[],
      eneShowKindList:[],
      rpt:'',      
      reportName:'',
      yearList:[],
      year:'',
      kind:'3',

      data:null,
      columns:null,
      pagination:false,
      xscroll: { x: 700,y: 500 },
      tenantInput:false,
      useEneFlag:[],
      titles:[],
      disable:false,
      visible:false,
      months:[],
      ek:'1',
      price:cost1.KV1,
      ptitle:this.$t("energy.enedata096")+':'+this.$t("energy.enedata097")+'/KWh (CNY)',
      costTitle:this.$t("energy.enedata084"),
      msel:false,
      defaultStatistic:0,
      month:"1",
      monthList:[],
      monthMap:null,
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.columns = [];
        this.data = [];
        this.initPage(0);
    }
  },
  mounted() { 
    //this.initPage(0);
  },
  beforeDestroy() {
   
  },
  methods:{
    mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
    priceChange(e){
      if(this.ek==='1'){
          this.price = cost1.KV1;
      }else if(this.ek==='2'){
          this.price = cost1.KV2;

      }else if(this.ek==='3'){
          this.price = cost1.KV3;

      }else if(this.ek==='4'){
          this.price = cost1.KV4;
      }
    },
    kindChanges(e){
      console.log(e,this.kind)
      this.initPage(this.year);
      this.columns = [];
      this.data = [];
    },
    computationalCost(){
      this.visible = true;
      this.ek = '1';
    },
    tableSize() {
        let height = this.$refs.main.clientHeight;
        let tableHeight = height - 160;
        //this.xscroll.y = tableHeight;
    },
    handleOk(){

        let price = this.price;
        let cvf = 100;

        if(this.rpt==='0'){
            if(element.eneKindGrNo==1){
                if(this.ek==='1'){
                    price = cost1.KV1;
                }else if(this.ek==='2'){
                    price = cost1.KV2;
                }else if(this.ek==='3'){
                    price = cost1.KV3;
                }else if(this.ek==='4'){
                    price = cost1.KV4;
                }
            }else if(element.eneKindGrNo==3){
                price = waterPrice;
            }
        }
        
        for (let i = 0; i < this.data.length; i++) {
            const element = this.data[i];
             let priceSum = 0;
             const cost = element.list[element.list.length-1];
            if(element.eneKindGrNo==1){
                const day = element.list[0];
                const night = element.list[1];
               
                if(cost.editApr === 'true'){
                  let pri = (day.apr*1+night.apr*1)*price/cvf;
                  let v = new Number(pri);
                  cost.apr = v.toFixed(0);
                }else{
                  if(cost.apr*1>0){
                    priceSum += cost.apr*1;
                  }
                }

                if(cost.editMay === 'true'){
                  let pri = (day.may*1+night.may*1)*price/cvf;
                  let v = new Number(pri);
                  cost.may = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.may*1>0){
                    priceSum += cost.may*1;
                  }
                }

            
                if(cost.editJun === 'true'){
                  let pri = (day.jun*1+night.jun*1)*price/cvf;
                  let v = new Number(pri);
                  cost.jun = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.jun*1>0){
                    priceSum += cost.jun*1;
                  }
                }

                if(cost.editJul === 'true'){
                  let pri = (day.jul*1+night.jul*1)*price/cvf;
                  let v = new Number(pri);
                  cost.jul = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.jul*1>0){
                    priceSum += cost.jul*1;
                  }
                }

                if(cost.editAug === 'true'){
                  let pri = (day.aug*1+night.aug*1)*price/cvf;
                  let v = new Number(pri);
                  cost.aug = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.aug*1>0){
                    priceSum += cost.aug*1;
                  }
                }

                if(cost.editSep === 'true'){
                  let pri = (day.sep*1+night.sep*1)*price/cvf;
                  let v = new Number(pri);
                  cost.sep = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.sep*1>0){
                    priceSum += cost.sep*1;
                  }
                }

                if(cost.editOct === 'true'){
                  let pri = (day.oct*1+night.oct*1)*price/cvf;
                  let v = new Number(pri);
                  cost.oct = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.oct*1>0){
                    priceSum += cost.oct*1;
                  }
                }

                if(cost.editNov === 'true'){
                  let pri = (day.nov*1+night.nov*1)*price/cvf;
                  let v = new Number(pri);
                  cost.nov = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.nov*1>0){
                    priceSum += cost.nov*1;
                  }
                }

                if(cost.editDec === 'true'){
                  let pri = (day.dec*1+night.dec*1)*price/cvf;
                  let v = new Number(pri);
                  cost.dec = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.dec*1>0){
                    priceSum += cost.dec*1;
                  }
                }

                if(cost.editJan === 'true'){
                  let pri = (day.jan*1+night.jan*1)*price/cvf;
                  let v = new Number(pri);
                  cost.jan = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.jan*1>0){
                    priceSum += cost.jan*1;
                  }
                }

                if(cost.editFeb === 'true'){
                  let pri = (day.feb*1+night.feb*1)*price/cvf;
                  let v = new Number(pri);
                  cost.feb = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.feb*1>0){
                    priceSum += cost.feb*1;
                  }
                }

                if(cost.editMar === 'true'){
                  let pri = (day.mar*1+night.mar*1)*price/cvf;
                  let v = new Number(pri);
                  cost.mar = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.mar*1>0){
                    priceSum += cost.mar*1;
                  }
                }

            }else if(element.eneKindGrNo==3){
                cvf = 1;
                const day = element.list[0];
                

                if(cost.editApr === 'true'){
                  let pri = day.apr*1*price/cvf;
                  let v = new Number(pri);
                  cost.apr = v.toFixed(0);
                }else{
                  if(cost.apr*1>0){
                    priceSum += cost.apr*1;
                  }
                }

                if(cost.editMay === 'true'){
                  let pri = day.may*1*price/cvf;
                  let v = new Number(pri);
                  cost.may = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.may*1>0){
                    priceSum += cost.may*1;
                  }
                }

            
                if(cost.editJun === 'true'){
                  let pri = day.jun*1*price/cvf;
                  let v = new Number(pri);
                  cost.jun = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.jun*1>0){
                    priceSum += cost.jun*1;
                  }
                }

                if(cost.editJul === 'true'){
                  let pri = day.jul*1*price/cvf;
                  let v = new Number(pri);
                  cost.jul = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.jul*1>0){
                    priceSum += cost.jul*1;
                  }
                }

                if(cost.editAug === 'true'){
                  let pri = day.aug*1*price/cvf;
                  let v = new Number(pri);
                  cost.aug = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.aug*1>0){
                    priceSum += cost.aug*1;
                  }
                }

                if(cost.editSep === 'true'){
                  let pri = day.sep*1*price/cvf;
                  let v = new Number(pri);
                  cost.sep = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.sep*1>0){
                    priceSum += cost.sep*1;
                  }
                }

                if(cost.editOct === 'true'){
                  let pri = day.oct*1*price/cvf;
                  let v = new Number(pri);
                  cost.oct = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.oct*1>0){
                    priceSum += cost.oct*1;
                  }
                }

                if(cost.editNov === 'true'){
                  let pri = day.nov*1*price/cvf;
                  let v = new Number(pri);
                  cost.nov = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.nov*1>0){
                    priceSum += cost.nov*1;
                  }
                }

                if(cost.editDec === 'true'){
                  let pri = day.dec*1*price/cvf;
                  let v = new Number(pri);
                  cost.dec = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.dec*1>0){
                    priceSum += cost.dec*1;
                  }
                }

                if(cost.editJan === 'true'){
                  let pri = day.jan*1*price/cvf;
                  let v = new Number(pri);
                  cost.jan = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.jan*1>0){
                    priceSum += cost.jan*1;
                  }
                }

                if(cost.editFeb === 'true'){
                  let pri = day.feb*1*price/cvf;
                  let v = new Number(pri);
                  cost.feb = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.feb*1>0){
                    priceSum += cost.feb*1;
                  }
                }

                if(cost.editMar === 'true'){
                  let pri = day.mar*1*price/cvf;
                  let v = new Number(pri);
                  cost.mar = v.toFixed(0);
                  priceSum += pri;
                }else{
                  if(cost.mar*1>0){
                    priceSum += cost.mar*1;
                  }
                }
            }

            let v = new Number(priceSum);
            cost.total = v.toFixed(0);
            cost.chgflg = true;
        }
          
        this.visible = false;
    },
    handleValChange( ov,value, key,col){

      // let v = new Number(value);
      // console.log(isNaN(v));
      // const newData = [...this.data];
      // const target = newData.filter(item => key === item.key)[0];
      // if(isNaN(v)){
      //     this.$message.error(this.$t('energy.enedata038'));
      //     if (target) {
      //         target[col] = ov;
      //     }
      //     return;
      // }
      // target['chgflg'] = true;

      // console.log('ov',ov);
      // console.log('value',value);
      // console.log('key',key);
      // console.log('col',col);
    },
    tenantInputChange(){

    },
    yearChange(e){
      this.initPage(e);
      this.columns = [];
      this.data = [];
    },
    save(){
        // let inputData = new Array();
        // for (let i = 0; i < this.data.length; i++) {
        //   const ele = this.data[i];
        //   if(ele.editApr =='true'){
        //     inputData.push(ele);
        //   } 
        // }
        let params =  {
            action:2,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            tenantInput:this.tenantInput,
            reportType:this.rpt,
            year:this.year,
            inputData:this.data,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }

        console.log("SaveEnergyActualInputData Params",params);
        this.loading = true;
        saveEnergyActualInputData(params)
        .then((res) => {
           console.log("SaveEnergyActualInputData Result",res);
            if(res.errorCode == '00'){
              this.$message.info(this.$t('energy.enedata081'));
              this.search();
            }else{
              this.$message.info(this.$t('energy.enedata082'));
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    csvout(){
         let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            year:this.year,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }

        console.log("downloadEnergyCSVDataWithURL Params",params);
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/data/actual/input/download',params)
        .then((res) => {
            let name  = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.reportName+'-';
            name += moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    rptChange(e){
      let t = parseInt(e.target.value);
      console.log('rptChange', t);
      if(t==3){
        this.reportName = this.eneKindList[4].text;
      }else if(t==4){
        this.reportName = this.eneKindList[3].text;
      }else{
        this.reportName = this.eneKindList[t].text;
      }
      this.columns = [];
      this.data = [];

      if(t==1){
        this.price = cost1.KV1;
        this.ptitle = this.$t("energy.enedata096")+':'+this.$t("energy.enedata097")+'/KWh (CNY)'
      }else if(t==3){
        this.price = waterPrice;
        this.ptitle = this.$t("energy.enedata096")+':'+this.$t("energy.enedata099")+'/m³ (CNY)'
      }
    },
    search(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            tenantInput:this.tenantInput,
            reportType:this.rpt,
            year:this.year,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("GetEnergyActualInputData Params",params);
        this.loading = true;
        getEnergyActualInputData(params)
        .then((res) => {
            console.log("GetEnergyActualInputData Result",res);
            if(res.errorCode == '00'){
                if(res.data.err == -1){
                    this.$message.error(this.$t('energy.enedata065'));
                }else if(res.data.err == -2){
                    this.$message.error(this.$t('energy.enedata066'));
                }else{

                    this.columns = res.data.columnList;
                    // for (let i = 0; i < this.columns.length; i++) {
                    //     const element = this.columns[i];
                    //     if(element.key === 'eneKind'){
                    //         element.customRender= this.csmtRender();
                    //         break;
                    //     }
                    // }
                    this.data    = res.data.list;
                    
                    this.titles = res.data.eneTableTitle;

                    // for (let i = 0; i < res.data.useEneFlag.length; i++) {
                        
                    //     if(!res.data.useEneFlag[i]){
                    //       this.useEneFlag.push('disabled'); 
                    //     }else{
                    //       this.useEneFlag.push(''); 
                    //     }
                    // }
                    //this.useEneFlag = res.data.useEneFlag;

                    // console.log(this.useEneFlag);
                    // if(con = res.data.useEneFlag.length){
                    //   this.disable = true;
                    // }
                }
            }
           
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    csmtRender(text, row, index){
        try{
            console.log(">>>>>",row["usage"]);
            return <a href="javascript:;">{text}</a>;
        }catch(e){
           console.log(">>>>>",e);
           return {
              children: <a href="javascript:;">{text}</a>,
              attrs: {
                colSpan: 18,
              },
            };
        }
    },
    initPage(year){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            year: year,
        };
        if(this.kind === '2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyActualInputData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                this.eneKindList	 =	res.data.eneKindList;
                this.rpt					 =	res.data.eneKindList[0].no;
                this.reportName    =  res.data.eneKindList[0].text;
                  this.yearList = res.data.yearList;
                if(year == 0){
                  this.year = res.data.fiscalYear;
                }

                this.useEneFlag = res.data.useEneFlag;
                this.eneShowKindList = new Array();
                let con = 0;
                for (let i = 0; i < res.data.useEneFlag.length; i++) {
                    
                    if(res.data.useEneFlag[i]){
                      this.eneShowKindList.push( this.eneKindList[i]);
                    }else{
                      con++;
                    }
                }

                if(con === res.data.useEneFlag.length){
                  this.disable = true;
                  this.eneShowKindList =  this.eneKindList;
                  this.$message.error(this.$t('energy.enedata080'));
                }else{
                   this.disable = false;
                }
                
               
                this.monthList               =  res.data.monthList;
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    }
    
  },
  destroyed(){
   
  },
  components: {
    "eai-table":eaiTable
  },
};
</script>

<style scoped>

#energyActualnputData {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width:  4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height: calc(100% - 130px);
    overflow: auto;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

button {
   margin-left: 15px;
}

</style>


